* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

#homePage {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

button {
  border: none;
  padding: 10px;
}

button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

button:active {
  transform: scale(0.9);
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  position: fixed;
  background-color: black;
  color: white;
  height: 60px;
  padding: 20px 10px;
  width: 100%;
  z-index: 99;
}

.nav-bar .nav-bar-links {
  text-decoration: none;
  color: white;
}

.nav-bar .active {
  color: rgb(161, 213, 255);
}

.nav-bar-right .nav-bar-links {
  margin: 0 10px;
}

.nav-bar .nav-bar-links:hover {
  color: rgba(201, 197, 197, 0.8);
}

img {
  object-fit: cover;
}
.big-banner {
  color: white;
  height: 700px;
  margin: 30px 20px;
  position: relative;
}

#new-product-banner {
  position: sticky;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./banner-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  height: 850px;
}

#big-promotion-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./banner-1.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  /* background-attachment: fixed; */
}

.big-banner-description {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 14%;
  transform: translateY(-50%);
  color: white;
}

.big-banner-description-text {
  margin: 10px 20px;
}

#new-product-banner .big-banner-description {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

footer {
  bottom: 0;
  left: 0;
  background-color: black;
  color: white;

  padding: 50px;
  width: 100vw;
}

#footer__main {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

footer form {
  width: 450px;
  display: flex;
  padding: 20px;
  padding-top: 0;
  flex-direction: column;
}

footer form input {
  margin: 10px 0px;
}

footer form textarea {
  margin: 10px 0px;
  width: 100%;
}

footer .copyright {
  text-align: center;
}

.features-cards-container {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.features-cards-container h2 {
  margin: 60px 0 0 30px;
}

.swiper {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 30px;
}

.swiper-slide {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.features-card-text {
  margin-top: 10px;
  width: 100%;
}

.features-card-text div {
  color: rgba(0, 0, 0, 0.487);
  margin-top: 10px;
}

.product-actions {
  display: flex;
}

.product-actions button {
  margin-right: 10px;
  margin-top: 10px;
}

.swiper-scrollbar {
  border-radius: 2px;
  height: 5px;
}

.products-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  margin: 100px 0;
  gap: 30px;
}

@media screen and (min-width: 1800px) {
  .products-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 1500px) {
  .products-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .products-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.product-query-container {
  display: flex;
  flex-direction: column;

  width: 750px;
  margin: auto;
  border: 1px solid lightgrey;
}

.product-searh-bar {
  border-bottom: 1px solid lightgrey;
  display: flex;
}

.product-searh-bar span {
  margin: auto;
}

.product-searh-bar input {
  border: none;
  width: 100%;
  height: 35px;
  padding: 20px;
  outline: none;
}

.product-filter {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 10px;
}

.product-filter div {
  margin: 10px 10px;
}

.product-filter div h3 {
  margin-bottom: 10px;
}

.product-filter div label {
  margin: 0 10px;
}

.product-filter input {
  margin: 0 6px;
}

#price-range-query {
  display: flex;
}

#product-details-page {
  display: flex;
  justify-content: center;
}

.product-detail-features {
  width: 1200px;
  margin: auto;
}

.product-detail-comments-container {
  margin: 30px auto;
  width: 1200px;
  padding: 30px;
  border-top: 1px solid lightgray;
}

.product-detail-comments {
  width: 100%;
  height: 100px;
  margin: 10px 0;
  display: flex;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.product-detail-comments-from {
  margin-top: auto;
  margin-left: auto;
}

#product-details-gallery {
  height: 500px;
  width: 500px;
  margin-right: 70px;
  border-right: 1px solid lightgray;
}

#product-details-gallery .swiper {
  width: 100%;
  height: 100%;
}

#product-details-gallery .swiper-slide {
  background-size: cover;
  background-position: center;
}

#product-details-gallery .mySwiper2 {
  height: 80%;
  width: 100%;
  padding: 25px;
}

#product-details-gallery .mySwiper2 img {
  border: 2px solid lightgray;
}

#product-details-gallery .mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 0px;
}

#product-details-gallery .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

#product-details-gallery .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

#product-details-gallery .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#product-details-page #product-details-description {
  padding: 60px;
}

#product-details-page #product-details-description p {
  margin: 30px 0px;
}

#product-details-page #product-details-description button.left-btn {
  border-radius: 20px 0px 0px 20px;
  border-right: 1px solid black;
}

#product-details-page #product-details-description button.right-btn {
  border-radius: 0px 20px 20px 0px;
  border-left: 1px solid black;
}

.cart-item-row-container {
  min-height: 100vh;
  padding: 100px;
}

.cart-item-row {
  display: flex;
  height: 230px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.123);
}

.cart-item-row .cart-item-row-description {
  margin: 20px 30px;
}

.cart-item-row .cart-item-row-description button {
  font-weight: bold;
}

.cart-item-row-quantity {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 1rem;
}

.cart-item-row-quantity button {
  height: 33px;
  width: 33px;
}

.cart-item-row-quantity span {
  margin: 0 10px;
}

.cart-total {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.payment-page-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  min-height: 100vh;
  width: 900px;
  padding: 10px;
  margin: 10px auto;
}

.payment-page-container button {
  margin-bottom: 30px;
}

.payment-page-container button:hover {
  transform: scale(1);
  outline: 1px solid black;
}

.payment-page-container button.active {
  color: aliceblue;
  background-color: black;
}

.payment-page-container .payment-page-delivery {
  margin-top: 19px;
}

.payment-page-container form {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border: 1px solid lightgray;
  padding: 23px;
  width: 500px;
}

.payment-page-container form label {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment-page-container form input {
  width: 70%;
  padding: 10px;
}
